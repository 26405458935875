import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { FileChecklistEndPoint, FileChecklistEndPointName } from '../global/components/file-checklist.endpoint';
import { ActivityLog } from '../global/models/activity-log';
import { AcivityType } from '../global/models/activity-type';
import { UserPhoto } from '../global/models/user-photo';

@Injectable({
  providedIn: 'root'
})
export class FileActivityLogService {
  constructor(private httpClient: HttpClient) { }

  getActivityLogList(externalSystemTaskId: number): Observable<ActivityLog[]> {
    const url = FileChecklistEndPoint.find((n) => n?.EndPointName === FileChecklistEndPointName.AllActivityLog)?.EndpointUrl;
    return this.httpClient.get<ActivityLog[]>(`${url}/${externalSystemTaskId}`)
      .pipe(
        map((logs: ActivityLog[]) => {
          return logs;
        })
      );
  }
  getActivityLogSearch(taskId: string, searchString: string): Observable<ActivityLog[]> {
    const url = FileChecklistEndPoint.find((n) => n?.EndPointName === FileChecklistEndPointName.AllActivityLogSearch)?.EndpointUrl;
    const req = {
      "PageRequest": {
        "PageNumber": 1,
        "PageSize": 100
      },
      "SortRequest": {
        "Field": "TaskId.keyword",
        "Direction": 1
      },
      "Filters": [
        {
          "Field": "Log.keyword",
          "Value": searchString,
          "Operator": 3,
          "DataType": 2
        },
        {
          "Field": "TaskId.keyword",
          "Value": taskId,
          "Operator": 1,
          "DataType": 1
        }
      ]
    }
    return this.httpClient.post(`${url}`, req)
      .pipe(
        map((results: any) => {
          return results.items as ActivityLog[];
        })
      );

  }
  getEmail_ActivityLogSearch(EmailMessageId: string, searchString: string): Observable<ActivityLog[]> {
    const url = FileChecklistEndPoint.find((n) => n?.EndPointName === FileChecklistEndPointName.EmailActivityLogSearch)?.EndpointUrl;
    const req = {
      "PageRequest": {
        "PageNumber": 1,
        "PageSize": 100
      },
      "SortRequest": {
        "Field": "EmailMessageId.keyword",
        "Direction": 1
      },
      "Filters": [
        {
          "Field": "Log.keyword",
          "Value": searchString,
          "Operator": 3,
          "DataType": 2
        },
        {
          "Field": "EmailMessageId.keyword",
          "Value": EmailMessageId,
          "Operator": 1,
          "DataType": 1
        }
      ]
    }
    return this.httpClient.post(`${url}`, req)
      .pipe(
        map((results: any) => {
          return results.items as ActivityLog[];
        })
      );

  }
  getActivityTypes(): Observable<AcivityType[]> {
    const url = FileChecklistEndPoint.find((n) => n?.EndPointName === FileChecklistEndPointName.AllActivityTypes)?.EndpointUrl;
    return this.httpClient.get<AcivityType[]>(`${url}`)
      .pipe(
        map((types: AcivityType[]) => {
          return types;
        })
      );
  }

  getUserImage(email: string): Observable<UserPhoto> {
    const url = `${FileChecklistEndPoint.find((n) => n?.EndPointName === FileChecklistEndPointName.UserDP)?.EndpointUrl}/${email}`;
    return this.httpClient.get<UserPhoto>(url
    );
  }
}
