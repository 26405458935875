<igps-lib-spinner [showSpinner]="showSpinner"></igps-lib-spinner>

<div class="file_info_card_height">
    <section class="ig2 file-panel">
        <!-- change to another component later -->
        <div class="file-tabs__header" *ngIf="transactionDetails.length==0 && isEmailSearchResult==false">
            Link the email to a task In order to view File info
        </div>
        <div class="file-tabs__header" *ngIf="transactionDetails.length==0 && isEmailSearchResult==true">
           File Info not found.
        </div>
        <section class="file-panel" *ngIf="transactionDetails.length>0">
            <!-- start: file tabs -->
            <section class="file-tabs">
                <!-- start: file tabs header -->
                <article class="file-tabs__header">
                    <button class="left-arrow-transaction" *ngIf="isFromDashboard">
                        <img src="./assets/icons/icon-arrow-left-dark.svg" alt="left-arrow" />
                    </button>
                    <div *ngIf="header">
                        <h2>{{ header }}</h2>
                        <div class="text-muted">
                            #{{ fileNumber }}
                            <span *ngIf="address"> | {{ address }} </span>
                        </div>
                    </div>
                </article>
                <!-- end: file tabs alert -->
                <mat-tab-group class="ig2 mat-tab-group-nested panel-height" disableRipple animationDuration="0ms"
                    [selectedIndex]="selectedIndex">
                    <mat-tab label="Transaction">
                        <lib-info-card header="Transaction Details" [items]="transactionDetails"></lib-info-card>
                        <lib-info-card header="Transaction Team" [items]="transactionTeams"></lib-info-card>
                    </mat-tab>
                    <mat-tab label="Parties">
                        <div class="parties_container">
                            <section class="info-card parties-card" *ngFor="let item of partyDetails; index as i">
                                <section name="partiesContent" class="info-card__body">
                                    <article class="info-card__row">
                                        <div class="info-card__col-left">
                                            {{ item.itemName }}
                                        </div>
                                        <div class="info-card__col-right" [ngSwitch]="item.itemObject?.templateType">
                                            <div *ngSwitchCase="'individual'">
                                                <div *ngFor="let contact of item?.itemObject?.markup; index as i">
                                                    <div class="contact-wrapper">
                                                        <!-- <secure-portal-badge *ngIf="contact?.principalId"
                                                            [principalId]="contact?.principalId"
                                                            [contactType]="contact?.contactType"
                                                            [contactId]="contact?.contactId">
                                                        </secure-portal-badge> -->
                                                        <div [innerHTML]="contact.contactName">
                                                        </div>
                                                    </div>
                                                    <div class="contact__wrapper"
                                                        [innerHTML]="item?.itemObject?.markup[i]?.phone">
                                                    </div>
                                                    <!-- <div class="contact__wrapper"
                                                        [innerHTML]="item?.itemObject?.markup[i]?.email">
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div *ngSwitchCase="'husbandWife'">
                                                <div *ngFor="let contact of item?.itemObject?.markup; index as i"
                                                    class="multi">
                                                    <div class="contact-wrapper">
                                                        <!-- <secure-portal-badge *ngIf="contact?.principalId"
                                                            [principalId]="contact?.principalId"
                                                            [contactType]="contact?.contactType"
                                                            [isPrimary]="contact?.isPrimary" [contactId]="contact?.contactId">
                                                        </secure-portal-badge> -->
                                                        <div [innerHTML]="contact.contactName">
                                                        </div>
                                                    </div>
                                                    <div class="contact__wrapper"
                                                        [innerHTML]="item?.itemObject?.markup[i]?.phone">
                                                    </div>
                                                    <!-- <div class="contact__wrapper"
                                                        [innerHTML]="item?.itemObject?.markup[i]?.email">
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div *ngSwitchCase="'businessTrust'">
                                                <ng-container
                                                    *ngIf="item?.itemObject?.markup[0].contactName && item?.itemObject?.markup[0].contactName.length > 0">
                                                    <div class="org-name"
                                                        [innerHTML]="item?.itemObject?.contacts[0].businessOrganizationName">
                                                    </div>
                                                    <div *ngFor="let contact of item?.itemObject?.contacts; index as i"
                                                        class="multi">
                                                        <div class="contact-wrapper">
                                                            <!-- <secure-portal-badge [principalId]="contact?.principalId"
                                                                [contactType]="contact?.principalType"
                                                                [contactId]="contact?.contactId">
                                                            </secure-portal-badge> -->
                                                            <div [innerHTML]="item?.itemObject?.markup[i]?.contactName">
                                                            </div>
                                                        </div>
                                                        <div class="contact__wrapper"
                                                            [innerHTML]="item?.itemObject?.markup[i]?.phone">
                                                        </div>
                                                        <!-- <div class="contact__wrapper"
                                                            [innerHTML]="item?.itemObject?.markup[i]?.email">
                                                        </div> -->
                                                    </div>
                                                </ng-container>
                                            </div>
                                            <div *ngSwitchCase="'org'">
                                                <div *ngFor="let contact of item?.itemObject?.contacts; index as i">
                                                    <div class="contact-wrapper">
                                                        <div [innerHTML]="item?.itemObject?.markup[i]?.name">
                                                        </div>
                                                    </div>
                                                    <div class="contact__wrapper"
                                                        [innerHTML]="item?.itemObject?.markup[i]?.phone">
                                                    </div>
                                                    <!-- <div class="contact__wrapper"
                                                        [innerHTML]="item?.itemObject?.markup[i]?.email">
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div *ngSwitchCase="'orgWithBadge'">
                                                <div *ngFor="let contact of item?.itemObject?.contacts; index as i">
                                                    <div class="contact-wrapper"
                                                        *ngIf="item?.itemObject?.markup[i]?.name && item?.itemObject?.markup[i]?.name.length > 0">
                                                        <!-- <secure-portal-badge [principalId]="contact?.id"
                                                            [contactType]="contact?.role">
                                                        </secure-portal-badge> -->
                                                        <div [innerHTML]="item?.itemObject?.markup[i]?.name">
                                                        </div>
                                                    </div>
                                                    <div class="contact__wrapper"
                                                        [innerHTML]="item?.itemObject?.markup[i]?.phone">
                                                    </div>
                                                    <!-- <div class="contact__wrapper"
                                                        [innerHTML]="item?.itemObject?.markup[i]?.email">
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div *ngSwitchCase="'lender'">
                                                <div *ngFor="let contact of item?.itemObject?.contacts; index as i">
                                                    <div class="contact-wrapper"
                                                        *ngIf="item?.itemObject?.markup[i]?.name && item?.itemObject?.markup[i]?.name.length > 0">
                                                        <!-- <secure-portal-badge [principalId]="contact?.id"
                                                            [contactType]="'individual'">
                                                        </secure-portal-badge> -->
                                                        <div [innerHTML]="item?.itemObject?.markup[i]?.name">
                                                        </div>
                                                    </div>
                                                    <div class="contact__wrapper"
                                                        [innerHTML]="item?.itemObject?.markup[i]?.phone">
                                                    </div>
                                                    <!-- <div class="contact__wrapper"
                                                        [innerHTML]="item?.itemObject?.markup[i]?.email">
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div *ngSwitchCase="'attorneyAgent'">
                                                <ng-container
                                                    *ngIf="item?.itemObject?.markup[0].contactName && item?.itemObject?.markup[0].contactName.length > 0">
                                                    <div [innerHTML]="item?.itemObject?.markup[0].name"></div>
                                                    <div *ngFor="let contact of item?.itemObject?.contacts; index as i">
                                                        <div class="contact-wrapper">
                                                            <!-- <secure-portal-badge [principalId]="contact?.id"
                                                                [contactType]="contact?.principalType">
                                                            </secure-portal-badge> -->
                                                            <div [innerHTML]="item?.itemObject?.markup[i]?.contactName">
                                                            </div>
                                                        </div>
                                                        <div class="contact__wrapper"
                                                            [innerHTML]="item?.itemObject?.markup[i]?.phone">
                                                        </div>
                                                        <!-- <div class="contact__wrapper"
                                                            [innerHTML]="item?.itemObject?.markup[i]?.email">
                                                        </div> -->
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </article>
                                </section>
                            </section>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </section>
            <!-- end: file tabs -->
        </section>
    </section>
</div>