import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { TransactionEnums } from '../global/enums/transaction-enum';
import { TeamEnums } from '../global/enums/transaction-team-enum';
import { DisplayTrnsactionDetail } from '../global/models/display-transaction-preview';
import { DisplayTeamData } from '../global/models/display-transaction-team';
import { TransactionInfoHeader } from '../global/models/transaction-info-header';
import { TransactionPreviewData } from '../global/models/transaction-preview';
import { FileInfoService } from './file-info.service';
import { ConvertTimePipe, PipeModule } from '@igps/pipe';
import { DatePipe } from '@angular/common';
import moment from 'moment';
@Injectable({
    providedIn: 'root',
})
export class TransactionInfoService {
    public header = '';
    public fileNumber!: string;
    public address = '';
    public transactionEnums = TransactionEnums;
    public teamEnums = TeamEnums;
    public transactionDetails: any = [];
    public transactionTeams: any = [];
    public transactionPreview!: TransactionPreviewData;
    public subscribeObjectList: Subscription[] = [];

    constructor(private fileInfoService: FileInfoService) { }

    private updatedTransactionDetails = new Subject<any[]>();
    updatedTransactionDetails$ = this.updatedTransactionDetails.asObservable();

    private updatedTransactionTeam = new Subject<any[]>();
    updatedTransactionTeam$ = this.updatedTransactionTeam.asObservable();

    private updatedTransactionHeader = new Subject<TransactionInfoHeader>();
    updatedTransactionHeader$ = this.updatedTransactionHeader.asObservable();

    public getTransactionsPreview(fileId: any, isFromEmail: boolean): void {
        const transactionPreview = this.fileInfoService
            .getTransactionPreview(fileId, isFromEmail).subscribe(
                (res: TransactionPreviewData) => {
                    if (res) {
                        this.transactionPreview = res;
                        this.headerData();
                        this.transactionDetailsData();
                        this.transactionTeamData();
                    }
                    else {
                        this.updatedTransactionDetails.next([]);
                    }
                }
            );
        this.subscribeObjectList.push(transactionPreview);
    }
    public headerData(): void {
        const header = this.transactionPreview.header;
        this.header = header.buyerSellerLastNames;
        this.fileNumber = header.fileNumber;
        this.address = header.fullAddress;

        this.updatedTransactionHeader.next({
            header: this.header,
            fileNumber: this.fileNumber,
            address: this.address,
        });
    }
    public transactionDetailsData(): void {
        const details = this.transactionPreview.transactionDetails;
        this.getLoans(details);
        this.formatDate(details);
        this.keepKeys(details);
        this.formatPrice(details);
        this.formatLoanNumber(details);
        const filteredDetails: DisplayTrnsactionDetail = this.sortObj(details);
        this.removeKeys(filteredDetails);
        this.transactionDetails = [];
        this.transactionDetails.length = 0;
        // eslint-disable-next-line prefer-const
        /* tslint:disable:no-string-literal */
        for (let [key, value] of Object.entries(filteredDetails)) {
            const transactionItems: any = {};
            const obj: any = this.transactionEnums;
            for (const enumKey in obj) {
                if (key === enumKey) {
                    key = obj[enumKey]
                }
            }
            transactionItems['itemName'] = key;
            transactionItems['itemValue'] = value;
            this.transactionDetails.push(transactionItems);
        }
        this.updatedTransactionDetails.next(this.transactionDetails);
    }
    public transactionTeamData(): void {
        const team = this.transactionPreview.transactionTeam;
        const filteredTeams: DisplayTeamData = this.sortTeamLabel(team);
        this.removeKeys(filteredTeams);
        this.transactionTeams = [];

        for (let [key, value] of Object.entries(filteredTeams)) {
            const teamItems: any = {};
            if (value !== null || value !== '') {
                // tslint:disable-next-line: forin
                for (const enumKey in this.teamEnums) {
                    const obj: any = this.teamEnums;
                    if (enumKey === key) {
                        key = obj[enumKey];
                        if (value.employeeId) {
                            this.removeKeys(value);
                            let fullName = '';
                            let businessPhone = '';
                            let email = '';

                            fullName = this.getOfficerName(value.firstName, value.lastName);

                            businessPhone = value.businessPhone;
                            // TODO: may use in the future implementation
                            // value.businessPhone
                            //     ? (businessPhone = `<div class="contact__wrapper"><div><img
                            //     class="parties-card__icon"
                            //     src="./assets/icons/icon-call-dark.svg"
                            //     alt="phone icon"/></div><div class="team__email">${value.businessPhone}</div></div>`)
                            //     : (businessPhone = undefined);

                            email = value.eMail;
                            // TODO: may use in the future implementation
                            // value.eMail
                            //     ? (email = `<div class="contact__wrapper"><div><img
                            //     class="parties-card__icon"
                            //     src="./assets/icons/icon-mail-dark.svg"
                            //     alt="email icon"/></div><div class="team__email">${value.eMail}</div></div>`)
                            //     : (email = undefined);

                            const contactInfo = [fullName, businessPhone, email];
                            const teamValue = this.formatTeamContactInfo(contactInfo);

                            value = teamValue.join('');
                        }
                        if (key === 'Escrow Owning Office' && team.escrowBusinessPhoneNumber !== null) {
                            value = `${team.escrowOwningOfficeName}\n${team.escrowBusinessPhoneNumber?.substring(0, 14)}`;
                        }

                        if (key === 'Title Owning Office' && team.titleBusinessPhoneNumber !== null) {
                            value = `${team.titleOwningOfficeName}\n${team.titleBusinessPhoneNumber?.substring(0, 14)}`;
                        }
                    }
                }

                teamItems['itemName'] = key;
                teamItems['itemValue'] = value;
                this.transactionTeams.push(teamItems);
            }
        }
        this.updatedTransactionTeam.next(this.transactionTeams);
    }

    public getLoans(transactionDetails: TransactionPreviewData['transactionDetails']): any {
        const loans = transactionDetails.newLoans;
        const loanItem: any = {};
        const loansKeys = [
            'firstNewLoan',
            'secondNewLoan',
            'thirdNewLoan',
            'fourthNewLoan',
            'fifthNewLoan',
            'sixthNewLoan',
            'seventhNewLoan',
            'eighthNewLoan',
            'ninethNewLoan',
        ];
        for (let i = 0; i < loans.length; i++) {
            if (loans[i].lender || loans[i].loanAmount) {
                loanItem[loansKeys[i]] = loans[i].loanAmount;
            }
        }
        transactionDetails = Object.assign(transactionDetails, loanItem);
        return transactionDetails;
    }
    public formatDate(obj: TransactionPreviewData['transactionDetails'] | any): void {
        const dateCategories = ['openDate', 'estimatedSettlementDate', 'settlementDate', 'prorateDate'];
        dateCategories.forEach((val) => {
            if (obj[val]) {
                const originalDate = new Date(obj[val]);
                const res = moment(originalDate).format('DD/MMM/YYYY');
                obj[val] = res;
            }
        });
        // PDT convertion commented to match with Inite date
        // var dPipe = new DatePipe('en-US');
        // var tPipe = new ConvertTimePipe(dPipe);
        // dateCategories.forEach((val) => {
        //     if (obj[val]) {
        //         const converted_date = tPipe.transform(obj[val], 'utc', 'us/pacific', 'dd/MMM/yyyy');
        //         obj[val] = converted_date;
        //     }
        // });
    }
    public keepKeys(obj: TransactionPreviewData['transactionDetails'] | any): void {
        const keepEnumKeys = ['businessSegmentName', 'transactionType', 'openDate'];
        keepEnumKeys.forEach((key) => {
            let keyVal: any = obj[key];
            if (keyVal === null || keyVal === '' || keyVal === undefined) {
                keyVal = ' ';
            }
        });
    }
    public formatPrice(obj: any): void {
        const p = 'salesPrice';
        // TODO: lint review
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const key in obj) {
            if (obj[p] && obj[p] > 0) {
                obj[p] = `$${this.formatCurrency(obj[p])}`;
                return;
            } else {
                delete obj[p];
            }
        }
    }
    public formatCurrency(val: string): any {
        return Number(parseFloat(val).toFixed(2)).toLocaleString('en', {
            minimumFractionDigits: 2,
        });
    }
    public formatLoanNumber(obj: TransactionPreviewData['transactionDetails'] | any): void {
        for (const property in obj) {
            if (property.toLowerCase().indexOf('loan') > -1 && !isNaN(obj[property])) {
                obj[property] = `$${this.formatCurrency(obj[property])}`;
            }
        }
    }
    public sortObj(obj: TransactionPreviewData['transactionDetails'] | any): DisplayTrnsactionDetail {
        const sortedObj: DisplayTrnsactionDetail = new DisplayTrnsactionDetail();
        Object.keys(this.transactionEnums).forEach((key) => {
            sortedObj[key as keyof DisplayTrnsactionDetail] = obj[key];
        });
        return sortedObj;
    }
    public removeKeys(obj: any): any {
        for (const key in obj) {
            if (obj[key] === null || obj[key] === '' || obj[key] === undefined) {
                delete obj[key];
            }
        }
        return obj;
    }
    public sortTeamLabel(obj: TransactionPreviewData['transactionTeam'] | any): DisplayTeamData {
        const sortedTeamLabel: DisplayTeamData = new DisplayTeamData();
        Object.keys(this.teamEnums).forEach((key) => {
            sortedTeamLabel[key as keyof DisplayTeamData] = obj[key];
        });
        return sortedTeamLabel;
    }
    public getOfficerName(firstName: string, lastName: string): string {
        let fullName = '';
        if (!firstName) {
            fullName = lastName;
        } else if (!lastName) {
            fullName = firstName;
        } else {
            fullName = `${firstName} ${lastName}`;
        }

        return fullName;
    }
    public formatTeamContactInfo(contactInfo: any[]): any {
        const teamValue = contactInfo.map((contact) => {
            if (contact !== undefined) {
                return `${contact}\n`;
            }
            return null;
        });

        return teamValue;
    }
    ngOnDestroy(): void {
        if (this.subscribeObjectList.length > 0) {
            this.subscribeObjectList.forEach((s) => {
                if (s) {
                    s.unsubscribe();
                }
            });
        }
    }
}