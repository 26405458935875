import { AuditableEntity } from './auditable-entity';

export class UserDto extends AuditableEntity {
  UserId!: number;
  Identity!: string;
  UserName!: string;
  FastUserId!: number;
  Initials!: string;
  FirstName!: string;
  LastName!: string;
  Domain!: string;
  IsAdmin!: boolean;
  IDaaSId!: string;
  UserFullName!: string;
  IsFirstLogin!: boolean;
}
