import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat',
})
export class TimeFormatPipe implements PipeTransform {
  transform(value: string): string {
    // Add Z to know the string is in UTC format
    if (value !== undefined) {
      if (value.indexOf('Z') === -1 && value.indexOf('+') === -1) {
        value += 'Z';
      }
      const timePipe = new Date(value);
      value = timePipe.toLocaleTimeString('en-US');
      return value;
    }
    return value;
  }
}
