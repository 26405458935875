import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
@Pipe({
  name: 'dateFormat2',
})
export class DateFormat2Pipe implements PipeTransform {
  transform(value: string): string {
    if (value !== undefined) {
      // Add Z to know the string is in UTC format
      if (value.indexOf('Z') === -1 && value.indexOf('+') === -1) {
        value += 'Z';
      }
      const dateValue = new Date(value);
      const datePipe = new DatePipe('en-US');
      const result = datePipe.transform(dateValue, 'dd/MMM/yyyy');
      return result!;
    }
    return value;
  }
}
