import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FileUtilityInitComponent } from './components/file-utility-init/file-utility-init.component';
import { FileNotesCardComponent } from './components/file-activity/file-notes/file-notes-card/file-notes-card.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { SpinnerModule } from '@igps/spinner';
import { InfoCardModule } from '@ui-core/info-card';
import { CommonNotesCardModule } from '@ui-core/common-notes-card';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FileActivityComponent } from './components/file-activity/file-activity.component';
import { FileNotesComponent } from './components/file-activity/file-notes/file-notes.component';
import { FileActivityLogComponent } from './components/file-activity/file-activity-log/file-activity-log.component';
import { FileInfoComponent } from './components/file-info/file-info.component';
import { FileAddNotesComponent } from './components/file-activity/file-notes/file-add-notes/file-add-notes.component';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptor } from './interceptor/header-interceptor';
import { AppInfoService } from './global/services/app-info.service';
import { DateFormat2Pipe } from './pipe/datepipe2';
import { TimeFormatPipe } from './pipe/timepipe';
import { FormsModule } from '@angular/forms';
import { ConvertTimePipe, PipeModule } from '@igps/pipe';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [
    AppComponent,
    FileUtilityInitComponent,
    FileActivityComponent,
    FileNotesComponent,
    FileActivityLogComponent,
    FileInfoComponent,
    FileAddNotesComponent,
    DateFormat2Pipe,
    TimeFormatPipe,
    FileNotesCardComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PipeModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatTabsModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    MatDialogModule,
    MatTooltipModule,
    MatIconModule,
    SpinnerModule,
    CommonNotesCardModule,
    InfoCardModule
  ],
  providers: [AppInfoService, ConvertTimePipe, {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpInterceptor,
    multi: true,
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
