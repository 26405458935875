export enum EntityType {
  Task = 161,
  TemplateTask = 162,
  File = 163,
  Template = 164,
  Group = 165,
  FileTask = 167,
  DynamicTask = 225,
  FileCheckList = 281,
  Appointment = 307,
  AppointmentChild = 310,
  CheckListAdmin = 249,
  PortalMessageAdmin = 313,
  MessageCenter = 251,
  Dashboard = 415,
}
