import { Injectable } from '@angular/core';
import { environment } from './environment';
import { Environment } from './environment.dto';
import environments from './environments.json'
@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  static environment = (environments as any)[environment.envName] as Environment;
}
