import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NoteFilter } from 'src/app/global/models/note-filter';
import { SubscriptionLike as ISubscription, Subscription } from 'rxjs';
import { CreateEmailNote, CreateNote } from 'src/app/global/models/create-note';
import { FileNotesService } from 'src/app/services/file-notes.service';
import { UpdateNote } from 'src/app/global/models/update-note';
import { refreshNoteList, selectedTaskId, GetSelectedProcess, onChangeProcess, GetEmailMessage, GetEmailTaskID, EmailMessageId } from '@igps/top-utility';
import { Process } from 'src/app/global/models/process';
@Component({
  selector: 'app-file-add-notes',
  templateUrl: './file-add-notes.component.html',
  styleUrls: ['./file-add-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileAddNotesComponent implements OnInit, OnDestroy, AfterViewInit {
  public subscribeObjectList: ISubscription[] = [];
  public notes!: number;
  public notesTo!: number;
  public maxLengthAllowedChars = 3000;
  public selectAllKeyValue = 65;
  public deleteKeyValue = 46;
  public backSpaceKeyValue = 8;
  public enterKeyValue = 13;
  public noteHeader = 'Add Note';
  public editnoteObj!: NoteFilter;
  public disbaleSaveBtn = false;
  public taskSource!: number;
  @ViewChild('divValue') private divValue: any;
  public notesData = '';
  onChangeSubscription!: Subscription;
  onSelectedProcessChangeSubscription!: Subscription;
  EmailtaskIdSubscription!: Subscription;
  emailTaskId!: string;
  emailMessageId!: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public ref: ChangeDetectorRef,
    public fileNoteService: FileNotesService, private cdref: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    this.subscribeEventEmitter();
    // tslint:disable-next-line: no-unused-expression
    if (this.divValue) { this.divValue.nativeElement.innerText = this.notesData; } else { this.divValue = "" }
  }
  public subscribeEventEmitter() {
    this.onSelectedProcessChangeSubscription = onChangeProcess.subscribe((process: Process) => {
      this.taskSource = process.taskSource;
    });

    if (this.taskSource === undefined) {
      const process = GetSelectedProcess();
      if (process?.id) {
        this.taskSource = process.taskSource
      }
      else {
        let x = sessionStorage.getItem('taskSource')
        this.taskSource = parseInt(x ? x : '')
      }
    }

    const emailMessageId = GetEmailMessage();
    this.emailMessageId = emailMessageId;


    const emailTaskId = GetEmailTaskID();
    this.emailTaskId = emailTaskId;
    this.cdref.detectChanges();

  }

  ngAfterViewInit(): void {
    if (this.data) {
      this.editnoteObj = this.data.noteobj;
      this.notesTo = this.editnoteObj.externalSystemTaskId;
      // tslint:disable-next-line: no-unused-expression
      if (this.divValue) { this.divValue.nativeElement.innerText = this.editnoteObj.description; }
      this.notesData = this.divValue.nativeElement.innerText;
      this.noteHeader = 'Edit Note';
      this.ref.detectChanges();
    }
  }
  saveNotes(): void {
    if (this.taskSource === 1) {
      const noteTaskId = sessionStorage.getItem('selectedNoteTaskId')?.toString();
      if (noteTaskId) {
        const createNote: CreateNote = {
          taskId: noteTaskId,
          notes: this.notesData.replace(/\n\n/g, '\n')
        };
        this.fileNoteService.saveTaskNotes(noteTaskId, createNote).subscribe(() => {
          const externalSystemTaskId = sessionStorage.getItem('selectedNoteTaskId');
          selectedTaskId.emit(externalSystemTaskId);
        });
      }
    }
    else if (this.taskSource === 2) { // any note under email dashboard goes to email notes table
      const emailMessageId = sessionStorage.getItem('selectedEmailNoteTaskId')?.toString();
      const createEmailNote: CreateEmailNote = {
        emailId: emailMessageId,
        notes: this.notesData.replace(/\n\n/g, '\n')
      };
      this.fileNoteService.saveEmailNotes(emailMessageId, createEmailNote).subscribe(() => {
        const emailMessageId = sessionStorage.getItem('selectedEmailNoteTaskId');
        EmailMessageId.emit(emailMessageId);
      });
    }

  }
  editNotes(): void {
    const note: UpdateNote = {
      status: this.editnoteObj.igniteStatusCd,
      notes: this.notesData.replace(/\n\n/g, '\n')
    };
    if (this.editnoteObj && this.editnoteObj.igpsNoteId) {
      if (this.taskSource === 1) {
        this.fileNoteService.updateNotes(this.editnoteObj.igpsNoteId, note).subscribe(() => {
          const externalSystemTaskId = sessionStorage.getItem('selectedNoteTaskId');
          selectedTaskId.emit(externalSystemTaskId);
        });
      }
      else {
        this.fileNoteService.updateEmailNotes(this.editnoteObj.igpsNoteId, note).subscribe(() => {
          const emailMessageId = sessionStorage.getItem('selectedEmailNoteTaskId');
          EmailMessageId.emit(emailMessageId);
        });
      }
    }
  }
  preventInvalidChar(event: any): any {
    if (event.key === '`' || event.key === '~' || event.key === '{' || event.key === '}' || event.key === '|') {
      return event.preventDefault();
    }
  }
  maxLengthLimit(event: any): any {
    if (event.ctrlKey && event.which === this.selectAllKeyValue) {
      return true;
    }
    if (
      event.which !== this.backSpaceKeyValue &&
      event.which !== this.deleteKeyValue &&
      this.notesData &&
      this.notesData.length >= this.maxLengthAllowedChars
    ) {
      return event.preventDefault();
    } else if (event.which === this.enterKeyValue && this.notesData && this.notesData.length >= this.maxLengthAllowedChars - 1) {
      return event.preventDefault();
    }
  }
  checkForInvalidPastedChars(event: ClipboardEvent): any {
    const invalidCharList = ['{', '}', '`', '~', '|'];
    const ClipboardData = event.clipboardData;
    const str = ClipboardData?.getData('text');
    if (invalidCharList.some((c) => str?.includes(c))) {
      return event.preventDefault();
    }
  }
  /* It will check max length of copy paste data */
  checkForMaxLengthPastedChars(event: ClipboardEvent): any {
    const ClipboardData = event.clipboardData;
    const str = ClipboardData?.getData('text');
    if (str && this.notesData && this.notesData.length + str.length >= this.maxLengthAllowedChars) {
      const remainingAllowedLength = this.maxLengthAllowedChars - this.notesData.length;
      if (str.length > remainingAllowedLength) {
        // tslint:disable-next-line: no-unused-expression
        if (this.divValue) { this.divValue.nativeElement.innerText = this.notesData + str.substring(0, remainingAllowedLength); }
        else {
          this.divValue = "";
        }
        this.notesData = this.divValue.nativeElement.innerText;
        return event.preventDefault();
      }
    } else if (str && str.length >= this.maxLengthAllowedChars) {
      // tslint:disable-next-line: no-unused-expression
      if (this.divValue) { this.divValue.nativeElement.innerText = str.substring(0, this.maxLengthAllowedChars); } else { this.divValue = ""; }
      this.notesData = this.divValue.nativeElement.innerText;
      return event.preventDefault();
    }
  }
  public ngOnDestroy(): void {
    if (this.subscribeObjectList.length > 0) {
      this.subscribeObjectList.forEach((s) => {
        if (s) {
          s.unsubscribe();
        }
      });
    }
  }

}
