import { UserDto } from './user';

export class AuditableEntity {
  createdUser?: UserDto;
  updatedUser?: UserDto;
  isDeleted = 0;
  createdUserId?: number;
  updatedUserId?: number;
  updateTimestamp = [];
  createdDate?: string;
  updatedDate?: string;
}
