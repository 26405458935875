import { Component } from '@angular/core';

@Component({
  selector: 'app-client-fileutility',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'IGPS-Client-File-Utility-UI';
}
