import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NoteStatus, NoteStatusMapping } from 'src/app/global/models/note-status';
import { NoteFilter } from 'src/app/global/models/note-filter';

@Component({
  selector: 'app-file-notes-card',
  templateUrl: './file-notes-card.component.html',
  styleUrls: ['./file-notes-card.component.scss']
})
export class FileNotesCardComponent {
  public noteStatusEnum = NoteStatus;

  @Input() note!:NoteFilter;
  @Input() timezone!:any;
  @Output() onNotesCardEditClicked = new EventEmitter<any>();
  @Output() onNotesCardArchiveClicked = new EventEmitter<any>();
  @Output() onNotesCardUnarchiveClicked = new EventEmitter<any>();
  constructor(){

  }
  notesCardEditClicked():void {
    this.onNotesCardEditClicked.emit();
  }
  notesCardArchiveClicked(): void {
    this.onNotesCardArchiveClicked.emit();
  }

  notesCardUnarchiveClicked(): void {
    this.onNotesCardUnarchiveClicked.emit();
  }

}
