import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileChecklistEndPoint, FileChecklistEndPointName } from '../components/file-checklist.endpoint';
import { ErrorLog } from '../models/error-log';

@Injectable({
  providedIn: 'root'
})

export class LoggerService {
  constructor(private httpClient: HttpClient) { }
  logApplicationErrors(error: ErrorLog) {
    const url = FileChecklistEndPoint.find((n) => n?.EndPointName === FileChecklistEndPointName.LogError)?.EndpointUrl;
    return this.httpClient.post(`${url}` + `logger/log/v1`, error);
  }
}
