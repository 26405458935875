import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-file-activity',
  templateUrl: './file-activity.component.html',
  styleUrls: ['./file-activity.component.scss']
})
export class FileActivityComponent {
  public isFileActivityEnabled = true;
}
