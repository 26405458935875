import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-file-utility-init',
  templateUrl: './file-utility-init.component.html',
  styleUrls: ['./file-utility-init.component.scss']
})
export class FileUtilityInitComponent {
  public fileChecklistNotification = false;
  public isUnread = false;
  public isFileMessagesEnabled = false;
  public selectedIndex!: number;
  @ViewChild('utilityTabGroup', { static: true }) utilityTabGroup: any;

  public utilityTabChange(event: MatTabChangeEvent): void {
    const tabChangeEvent = {
      event,
      collapseExpanded: event.tab.textLabel !== 'Notifications',
    };
  }
}
