import { Injectable } from "@angular/core";
import { TransactionParties } from "../global/models/transaction-parties";
import { FileInfoService } from "./file-info.service";
import { Subject, Subscription } from 'rxjs';
import { PartiesDetails } from "../global/models/parties-details";
import { TransactionInfoParty } from "../global/models/transaction-info-party";
import { Contact } from "../global/models/contact";
import { Buyers } from "../global/models/buyers";
import { ContactType } from "../global/enums/contact-type";

@Injectable({
    providedIn: 'root',
})
export class TransactionPartiesService {
    public subscribeObjectList: Subscription[] = [];
    transactionPartiesDetail!: TransactionParties;
    partyDetails: any = [];
    partiesHolder: TransactionInfoParty[] = [];

    constructor(private fileInfoService: FileInfoService) { }

    private updatedTransactionParties = new Subject<any[]>();
    updatedTransactionParties$ = this.updatedTransactionParties.asObservable();

    getPartyDetails(fileId: any,isFromEmail: boolean): void {
        const partyDetailsObj = this.fileInfoService.getTransactionParties(fileId,isFromEmail)
            .subscribe(
                (res: TransactionParties) => {
                    if (res) {
                        this.transactionPartiesDetail = res;
                        this.partiesDetailData();
                        this.updatedTransactionParties.next(this.partyDetails);
                    }
                    else {
                        this.updatedTransactionParties.next([]);
                    }
                }
            );
        this.subscribeObjectList.push(partyDetailsObj);
    }
    public partiesDetailData(): void {
        const otherParties = this.transactionPartiesDetail.otherParties;
        const buyersDetail = this.transactionPartiesDetail.buyers;
        const sellersDetail = this.transactionPartiesDetail.sellers;

        this.partyDetails = [];
        if (buyersDetail) { this.getbuyersSellersPartyDetail(buyersDetail, 'Buyer'); }
        if (sellersDetail) { this.getbuyersSellersPartyDetail(sellersDetail, 'Seller'); }

        const filteredOtherParties: PartiesDetails[] = this.getOtherPartiesWithNoNewLenders(otherParties);

        this.getOtherPartiesDetail(filteredOtherParties);
        const newLendersRelatedParties: PartiesDetails[] = this.filterOutNewLenders(otherParties);
        this.formatNewLenderRelatedParties(newLendersRelatedParties);
    }
    public getbuyersSellersPartyDetail(details: Buyers[], partyRole: string): void {
        details.forEach((value) => {
            const partiesItems: any = {};
            const businessOrganization = value.businessOrganizationInfo;
            const contacts = value.contacts;

            if (businessOrganization) {
                /* tslint:disable:no-string-literal */
                const phoneType = this.getPhoneType(businessOrganization);
                const partyDetails = this.formatPartiesDetailData(businessOrganization, 'businessOrganizationName', phoneType, '', false);
                partiesItems['itemName'] = partyRole;
                partiesItems['itemValue'] = partyDetails;
                const itemObject: any = { contacts: [businessOrganization] };
                itemObject['markup'] = this.partiesHolder;
                itemObject['templateType'] = this.mapPrincipalTypeToTemplate(itemObject);
                partiesItems['itemObject'] = itemObject;
                this.partyDetails.push(partiesItems);
                this.partiesHolder = [];
            } else {
                let partyContact: any = [];
                let entity = '';
                const itemObject: any = { contacts: [] };
                partyContact = contacts?.map((contact) => {
                    contact['businessOrganizationName']
                        ? (entity = `<div class="business_entity">${contact['businessOrganizationName']}</div>`)
                        : (entity = '');
                    const phoneType = this.getPhoneType(contact);
                    itemObject.contacts = contacts;
                    let isPrimary: boolean = false;
                    if (contact.principalType === ContactType.husbandWife) {
                        const indexOfContact = contacts.indexOf(contact);
                        if (indexOfContact === 0) {
                            isPrimary = true;
                        } else {
                            isPrimary = false;
                        }
                    }

                    return this.formatPartiesDetailData(contact, 'contactName', phoneType, 'buyerSeller', isPrimary);
                });
                partiesItems['itemName'] = partyRole;
                partiesItems['itemValue'] = [entity, ...partyContact.join('\n')].join('');
                itemObject['templateType'] = this.mapPrincipalTypeToTemplate(itemObject);
                itemObject['markup'] = this.partiesHolder;
                partiesItems['itemObject'] = itemObject;
                this.partyDetails.push(partiesItems);
                this.partiesHolder = [];
            }
        });
    }
    public getPhoneType(contact: Contact): string {
        let phoneType: string;
        contact['principalType'] === 'Business Entity' ? (phoneType = 'businessPhone') : (phoneType = 'homePhone');
        return phoneType;
    }
    public formatPartiesDetailData(contact: Contact | any, entityName: string, phoneType: string, partyRole: string, isPrimary: boolean | null): string {
        let name = '';
        let contactName = '';
        let phone = '';
        let email = '';

        if (partyRole !== 'buyerSeller') {
            name = `<div class="contact__wrapper">${contact[entityName]}</div>`;
        }

        if (contact['contactName']) {
            contactName = `<div class="contact__wrapper">${contact['contactName']}</div>`;
        }
        if (contact['cellular']) {
            phone = `<div class="contact__wrapper"><img
                                    class="parties-card__icon"
                                    src="./assets/icons/icon-mobilephone-dark.svg"
                                    alt="mobile phone icon"/>${contact['cellular']}</div>`;
        } else if (contact[phoneType]) {
            phone = `<div class="contact__wrapper"><img
                                    class="parties-card__icon"
                                    src="./assets/icons/icon-call-dark.svg"
                                    alt="phone icon"/>${contact[phoneType]}</div>`;
        } else {
            phone = '';
        }
        if (contact['email']) {
            email = `<div class="contact__wrapper"><img
                                      class="parties-card__icon"
                                      src="./assets/icons/icon-mail-dark.svg"
                                      alt="email icon"/>${contact['email']}</div>`;
        }
        const result = this.processPartyElement(name, contactName, phone, email, contact, isPrimary);

        return result;
    }
    public processPartyElement(
        name: string,
        contactName: string,
        phone: string,
        email: string,
        contact: Contact,
        isPrimary: boolean | null
    ): string {
        const party = this.mapPartyElement(name, contactName, phone, email, contact, isPrimary);
        this.partiesHolder.push(party);

        return `${name}${contactName}${phone}${email}`;
    }
    public mapPartyElement(
        partyName: string,
        partyContactName: string,
        partyPhone: string,
        partyEmail: string,
        partyContact: Contact,
        partyIsPrimary: boolean | null
    ): TransactionInfoParty {
        const element = {
            name: partyName,
            contactName: partyContactName,
            phone: partyPhone,
            email: partyEmail,
            principalId: partyContact.principalId,
            isPrimary: partyIsPrimary,
            contactType: partyContact.principalType,
            contactId: partyContact.contactId,
        } as TransactionInfoParty;

        return element;
    }
    public mapRoleTypeToTemplate(roleTypeId: number, contactName: string): string {
        switch (roleTypeId) {
            case 690: // 'Business Source'
                return 'org';
            case 2368: // 'Buyer’s RE Broker Transaction Coordinator'
                if (contactName && contactName.length > 0) {
                    return 'attorneyAgent';
                } else {
                    return 'orgWithBadge';
                }
            case 2371: // 'Seller’s RE Broker Transaction Coordinator'
                if (contactName && contactName.length > 0) {
                    return 'attorneyAgent';
                } else {
                    return 'orgWithBadge';
                }
            default:
                if (contactName && contactName.length > 0) {
                    return 'attorneyAgent';
                } else {
                    return 'org';
                }
        }
    }
    public mapPrincipalTypeToTemplate(itemObject: { contacts: any[] }): string {
        const contacts = itemObject['contacts'];
        if (contacts && contacts.length > 0) {
            const firstContact = contacts[0];
            const principalType = firstContact['principalType'];
            switch (principalType) {
                case 'Individual':
                    return 'individual';
                case 'Husband/Wife':
                    return 'husbandWife';
                case 'Business Entity':
                    if (firstContact.contactName && firstContact.contactName.length > 0) {
                        return 'businessTrust';
                    } else {
                        return 'org';
                    }
                case 'Trust/Estate':
                    if (firstContact.contactName && firstContact.contactName.length > 0) {
                        return 'businessTrust';
                    } else {
                        return 'org';
                    }
                default:
                    return 'org';
            }
        } else {
            return 'org';
        }
    }
    public getOtherPartiesWithNoNewLenders(details: PartiesDetails[]): PartiesDetails[] {
        return details.filter((value) => value.role !== 'New Lender');
    }
    public getOtherPartiesDetail(details: PartiesDetails[] | any): void {
        details.forEach((value: any) => {
            const partiesItems: any = {};
            let key = value.role;
            let otherPartiesDetails = '';

            otherPartiesDetails = this.formatPartiesDetailData(value, 'businessPartyName', 'businessPhone', '', false);
            // tslint:disable-next-line: no-unused-expression
            if (key === `Buyer's Real Estate Agent`) { key = `Buyer's RE Agent` }
            // tslint:disable-next-line: no-unused-expression
            if (key === `Seller's Real Estate Agent`) { key = `Seller's RE Agent` }
            // tslint:disable-next-line: no-unused-expression
            if (key === `Buyer's RE Broker Transaction Coordinator`) { key = `Buyer's TC` }
            // tslint:disable-next-line: no-unused-expression
            if (key === `Seller's RE Broker Transaction Coordinator`) { key = `Seller's TC` }

            partiesItems['itemName'] = key;
            partiesItems['itemValue'] = otherPartiesDetails;
            const template = this.mapRoleTypeToTemplate(value['roleTypeId'], value.contactName);
            partiesItems['itemObject'] = { contacts: [value], templateType: template, markup: this.partiesHolder };
            this.partyDetails.push(partiesItems);
            this.partiesHolder = [];
            this.sortParties(this.partyDetails);
        });
    }
    public sortParties(partiesDetail: any[]): void {
        partiesDetail.sort((a, b) => {
            const partyA = a.itemName.toUpperCase();
            const partyB = b.itemName.toUpperCase();
            return partyA < partyB ? -1 : partyA > partyB ? 1 : 0;
        });
    }
    public formatNewLenderRelatedParties(parties: PartiesDetails[] | any): void {
        const items: any = [];
        const orderedParties = parties.sort(this.compare_sequence);
        orderedParties.forEach((party: any) => {
            const lendersItems: any = {};
            const itemObject: any = { contacts: [] };
            let details = '';
            const roleTypeId = party['roleTypeId'];
            details = this.formatPartiesDetailData(party, 'businessPartyName', 'businessPhone', '', false);
            lendersItems['itemName'] = party.role;
            lendersItems['itemValue'] = details;
            itemObject['templateType'] = this.mapRoleTypeToTemplate(roleTypeId, party?.contactName);
            itemObject['markup'] = this.partiesHolder;
            itemObject['contacts'] = [party];
            lendersItems['itemObject'] = itemObject;
            items.push(lendersItems);
            this.partiesHolder = [];
            if (party.relatedParties) {
                const relatedOfficers: any = [];
                const relatedParties = party.relatedParties;
                relatedParties.forEach((value: any) => {
                    const relatedPartyItems: any = {};
                    let details2 = '';
                    const relatedRoleTypeId = value['roleTypeId'];
                    details2 = this.formatPartiesDetailData(value, 'businessPartyName', 'businessPhone', '', false);
                    value.role = this.renameLenders(value);
                    relatedPartyItems['itemName'] = value.role;
                    relatedPartyItems['itemValue'] = details2;
                    const relatedItemObject: any = { contacts: [] };
                    relatedItemObject['templateType'] = this.mapRoleTypeToTemplate(relatedRoleTypeId, value?.contactName);
                    relatedItemObject['markup'] = this.partiesHolder;
                    relatedItemObject['contacts'] = [value];
                    relatedPartyItems['itemObject'] = relatedItemObject;
                    relatedOfficers.push(relatedPartyItems);
                    this.partiesHolder = [];
                });
                this.sortParties(relatedOfficers);
                items.push(...relatedOfficers);
            }
        });
    }
    compare_sequence(compareSequence: any) {
        throw new Error('Method not implemented.');
    }
    public renameLenders(party: PartiesDetails | any): string {
        let result = party.role;
        const role = party.role;
        const roleId = party['roleTypeId'];
        if (roleId === 3220 || role === 'Lender- Loan Officer') {
            result = 'Loan Officer';
        }
        if (roleId === 3221 || role === 'Lender- Processor') {
            result = 'Loan Processor';
        }

        return result;
    }
    public filterOutNewLenders(details: PartiesDetails[]): PartiesDetails[] {
        return details.filter((value) => value.role === 'New Lender');
    }
    ngOnDestroy(): void {
        if (this.subscribeObjectList.length > 0) {
            this.subscribeObjectList.forEach((s) => {
                if (s) {
                    s.unsubscribe();
                }
            });
        }
    }
}