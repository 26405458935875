<div *ngIf="note" class="notes-card" id="notes-card-{{ note.igpsNoteId }}-{{ note.igniteStatusCd }}">
    <lib-common-notes-card [content]="note.description"
        [name]="note.createdUser=='Rule Admin'?'Ignite':note.createdUser"
        [timestamp]="(note.updatedDate == null ? note.createdDate : note.updatedDate)| convertTime: 'utc' : timezone.value"
        [task]="note.actionType?note.taskType:note.actionType" [notesId]="note.igpsNoteId"
        [notesStatus]="note.igniteStatusCd" [creator]="note.creatorUser" [editor]="note.editorUser"
        [avatar]="note.profileImage"
        [initial]="note.createdUserInitials=='Rule Admin'?'Ignite':note.createdUserInitials" [child]="false"
        [source]="utility" [entityTypeId]="163" [showPinIcon]="false" [showExpandCollapse]="true"
        [charLimit]="192"></lib-common-notes-card>
    <div class="notes-card--action">
        <div class="">
            <button *ngIf="
          note.igniteStatusCd !== noteStatusEnum.Archived" matTooltip="Archive" mat-icon-button aria-label="Archive"
                (click)="notesCardArchiveClicked()">
                <!-- <lib-icon name="archive"></lib-icon> -->
                <span class="ids-icon ids-icon--size-standard ids-icon--theme-navy"><svg
                        xmlns="http://www.w3.org/2000/svg" fill="#043B7C" viewBox="0 0 24 24">
                        <path d="M22 4v5h-1v11H3V9H2V4h20Zm-2 5H4v10h16V9Zm-4.5 2v1h-7v-1h7ZM21 5H3v3h18V5Z"></path>
                        <path d="M22 4v5h-1v11H3V9H2V4h20Zm-2 5H4v10h16V9Zm-4.5 2v1h-7v-1h7ZM21 5H3v3h18V5Z"></path>
                    </svg></span>

            </button>
            <button *ngIf="note.igniteStatusCd === noteStatusEnum.Archived" matTooltip="Unarchive" mat-icon-button
                aria-label="UnArchive" (click)="notesCardUnarchiveClicked()">
                <!-- <lib-icon name="archive_filled"></lib-icon> -->
                <span class="ids-icon ids-icon--size-standard ids-icon--theme-navy">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#043B7C" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M22 9V4H2v5h1v11h18V9h1Zm-6.5 2h-7v1h7v-1Z" clip-rule="evenodd">
                        </path>
                    </svg>
                </span>
            </button>
            <button *ngIf="note.igniteStatusCd !== noteStatusEnum.Archived" matTooltip="Edit" mat-icon-button
                aria-label="Edit" (click)="notesCardEditClicked()">
                <!-- <lib-icon name="pencil"></lib-icon> -->
                <span class="ids-icon ids-icon--size-standard ids-icon--theme-navy"> <svg
                        xmlns="http://www.w3.org/2000/svg" fill="#043B7C" viewBox="0 0 24 24">
                        <path
                            d="m19.76 2.657 1.583 1.584a2.24 2.24 0 0 1 0 3.169L9.461 19.29c-.101.101-.22.182-.351.238l-5.545 2.377c-.93.398-1.869-.542-1.471-1.47L4.47 14.89c.056-.131.137-.25.237-.35L16.59 2.656a2.24 2.24 0 0 1 3.17 0ZM8.668 18.5l8.713-8.714-3.168-3.168L5.5 15.331 8.669 18.5Zm-1.11.476L5.025 16.44l-1.9 4.437 4.436-1.901Zm10.615-9.982 2.377-2.376a1.12 1.12 0 0 0 0-1.584l-1.584-1.587a1.12 1.12 0 0 0-1.585 0l-2.376 2.378 3.168 3.168Z">
                        </path>
                    </svg>
                </span>
            </button>
        </div>
    </div>
</div>