import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CheckListUserPreference } from '../models/checklist-user-preference';
import { FASTInfo } from '../models/fast-info';
import { FileInformation } from '../models/file-information';
import { UserDto } from '../models/user';
import { UserPreference } from '../models/user-preference';
import { getUserInfo } from '@igps/client-root-config';

@Injectable()
export class AppInfoService {
  public FastInfo!: FASTInfo;
  public UserInfo!: UserDto;
  // this is not used in Ig 2.0 can be removed later
  public userPreferences!: UserPreference;
  public userUPN!: string;
  public checkListUserPreferences!: CheckListUserPreference;
  public showHttpErrorDialog: EventEmitter<any> = new EventEmitter();
  public formGroupEstateChange: EventEmitter<any> = new EventEmitter();
  public deactivateNotificationData: EventEmitter<any> = new EventEmitter();
  public fileSummaryInfo: any
  public isLogOut = false;
  public FileInformation: Subject<FileInformation> = new Subject();
  public showRetryAlertBox: EventEmitter<boolean> = new EventEmitter();
  public fileSummaryDetails: any;
  public navBackRoutes: string[] = [];
  public subscribeGlobalNavToggle = new Subject();
  public showFileMenu: EventEmitter<any> = new EventEmitter();

  // public checkIsGlobalNavToggleEnabled(): boolean {
  //   const isGlobalNavToggleEnabled = environment.ignite.allowSwitchGlobalNavView;
  //   if (isGlobalNavToggleEnabled) {
  //     return !!sessionStorage.getItem('globalNavToggle');
  //   } else {
  //     async () => {
  //       await checkFeatureAccess(FeatureToggleNames.ACT_LOG).then((isEnabled) => {
  //         return isEnabled;
  //       });
  //     };
  //   }
  // }

  // TODO: clean up later, unrelated to File Utility
  // public loadIgniteFile(fastFileId: number, fastFileNumber: string, enableFileCheckList: boolean, routeModule: string): void {
  //   // this.removeNotificationData();
  //   const obj = { route: routeModule, enableFileCheckList: enableFileCheckList };
  //   if (fastFileId > 0) {
  //     sessionStorage.setItem('fileid', fastFileId.toString());
  //     this.FastInfo.FastFileId = fastFileId;
  //   }
  //   this.FastInfo.FileId = undefined;
  //   this.FastInfo.FastFileNumber = fastFileNumber;
  //   this.appInitService.emitToNavigate.emit(obj);
  // }

  // TODO: clean up later, unrelated to File Utility
  // public loadFastFile(fastFileId: number, fastFileNumber: string): void {
  //   const FASTInfo = new FASTInfo();
  //   FASTInfo.FastFileId = fastFileId;
  //   FASTInfo.FastFileNumber = fastFileNumber;
  //   // trigger event to open Ignite file in Fast
  //   this.appInitService.emitInvokeIgniteMessagetoFast.emit(FASTInfo);
  // }

  // TODO: clean up later, unrelated to File Utility
  // public removeNotificationData(): void {
  //   // Call deactivation data to remove opened file entry from notification data
  //   if (this.FastInfo && this.FastInfo.FileId) {
  //     this.deactivateNotificationData.emit(false);
  //   } else {
  //     this.appInitService.isremovedNotificationData = true;
  //   }
  // }

  public initialize(): void {
    // public initialize(authService: MsalService): void {
    const account = getUserInfo();
    // const names = account.name.split(' ');
    this.UserInfo = new UserDto();
    this.UserInfo.UserId = account.id;
    this.UserInfo.FirstName = account.basicInfo.firstName;
    this.UserInfo.LastName = account.basicInfo.lastName;
    this.UserInfo.Initials = `${this.UserInfo.FirstName[0]}${this.UserInfo.LastName[0]}`;
    this.UserInfo.UserName = account.basicInfo.userName.split('\\')[1];
    // this.UserInfo.UserFullName = account.name;
    // this.UserInfo.Domain = account.idTokenClaims.onpremADusername.split('\\')[0];
    // this.UserInfo.IDaaSId = authService.IDaaSID;
    // this.FastInfo = new FASTInfo();
  }

  public createEmployeeUPN(userName: string): string {
    const emailSuffix = '@firstam.com';
    let result = userName;
    const userNameParts = userName.split(/(\\|\/)/);
    if (userNameParts.length === 3) {
      result = userNameParts[2];
    }
    result = result.concat(emailSuffix).toLowerCase();
    return result;
  }
}
