import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileChecklistEndPoint, FileChecklistEndPointName } from '../global/components/file-checklist.endpoint';
import { TransactionParties } from '../global/models/transaction-parties';
import { TransactionPreviewData } from '../global/models/transaction-preview';



@Injectable({
  providedIn: 'root'
})
export class FileInfoService {
  constructor(private httpClient: HttpClient) { }

  getTransactionPreview(fileId: any, isFromEmail: boolean): Observable<TransactionPreviewData> {
    if (isFromEmail) {
      const eUrl = FileChecklistEndPoint.find((n) => n?.EndPointName === FileChecklistEndPointName.FastTransacrionPreview)?.EndpointUrl;
      return this.httpClient.get<TransactionPreviewData>(`${eUrl}?fileId=${fileId}`);
    }
    else {
      const url = FileChecklistEndPoint.find((n) => n?.EndPointName === FileChecklistEndPointName.TransacrionPreview)?.EndpointUrl;
      return this.httpClient.get<TransactionPreviewData>(`${url}/${fileId}`);
    }
  }
  getTransactionParties(fileId: any, isFromEmail: boolean): Observable<TransactionParties> {
    if (isFromEmail) {
      const eUrl = FileChecklistEndPoint.find((n) => n?.EndPointName === FileChecklistEndPointName.FastTransactionParties)?.EndpointUrl;
      return this.httpClient.get<TransactionParties>(`${eUrl}?fileId=${fileId}`);
    }
    else {
      const url = FileChecklistEndPoint.find((n) => n?.EndPointName === FileChecklistEndPointName.TransactionParties)?.EndpointUrl;
      return this.httpClient.get<TransactionParties>(`${url}/${fileId}`);
    }
  }
  getprocessdetails(id: any) {
    const url = FileChecklistEndPoint.find((n) => n?.EndPointName === FileChecklistEndPointName.LogError)?.EndpointUrl;
    return this.httpClient.get(`${url}domaindata/prdprocess/v1/id/${id}`);
  }
}
