import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { SubscriptionLike as ISubscription, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { EntityType } from 'src/app/global/enums/entity-type.enum';
import { EmailNoteFilter, NoteFilter } from 'src/app/global/models/note-filter';
import { AppInfoService } from 'src/app/global/services/app-info.service';
import { FileNotesService } from 'src/app/services/file-notes.service';
import { FileAddNotesComponent } from './file-add-notes/file-add-notes.component';
import { UpdateNote } from 'src/app/global/models/update-note';
import { NoteStatus, NoteStatusMapping } from 'src/app/global/models/note-status';
import { refreshNoteList, selectedTaskId, showToastEvent, GetSelectedProcess, GetEmailMessage, EmailMessageId, onChangeProcess } from '@igps/top-utility';
import { CommonMessages, getTimezone, timezone$ } from '@igps/client-root-config';
import { NotesSourceType } from 'src/app/global/enums/notes-source-type';
import { ActionTypes } from 'src/app/global/enums/action-type';
import { Process } from 'src/app/global/models/process';
import { FileInfoService } from 'src/app/services/file-info.service';

@Component({
  selector: 'app-file-notes',
  templateUrl: './file-notes.component.html',
  styleUrls: ['./file-notes.component.scss']
})
export class FileNotesComponent implements OnInit, OnDestroy {
  showSpinner: boolean = false;
  public subscribeObjectList: ISubscription[] = [];
  public noteList: NoteFilter[] = [];
  public noteFilerList: NoteFilter[] = [];
  public entityType = EntityType;
  public noteStatusEnum = NoteStatus;
  public sourceTypeEnum = NotesSourceType;
  statusMapping = NoteStatusMapping;
  commonMessages = CommonMessages;
  keys = Object.keys;
  noteStatus: string = this.noteStatusEnum[this.statusMapping.All];
  timezone: { name: string; value: string; abbreviation: string } = { name: "", value: "", abbreviation: "" };
  onSelectedProcessChangeSubscription!: Subscription;
  taskSource!: number;
  fileNoteId!: string | null;
  constructor(public dialog: MatDialog,
    public fileNoteService: FileNotesService,
    private fileInfoService: FileInfoService,
    public appInfoService: AppInfoService,
    private ngZone: NgZone) {
  }

  ngOnInit(): void {
    this.appInfoService.initialize();
    this.subscribeEvents();
    this.subscribeTask();
    this.subscribeNoteList();
    this.timezone = getTimezone();
    this.subscribeObjectList.push(timezone$.subscribe((timezone: { name: string; value: string; abbreviation: string }) => {
      this.ngZone.run(() => {
        this.timezone = timezone;
      });
    }));
  }

  public subscribeEvents() {
    this.onSelectedProcessChangeSubscription = onChangeProcess.subscribe((process: Process) => {
      this.taskSource = process.taskSource;
    });
    if (this.taskSource === undefined) {
      const process = GetSelectedProcess();
      if (process?.id) {
        this.taskSource = process.taskSource
      }
      else {
        let x = sessionStorage.getItem('taskSource')
        this.taskSource = parseInt(x ? x : '')
      }
    }
  }

  public subscribeTask() {
    const subscribeNoteListObj = selectedTaskId.subscribe(
      (value: any) => {
        if (value) {
          this.ngZone.run(() => {
            const _taskId = sessionStorage.getItem('selectedNoteTaskId');
            if (_taskId !== value) {
              sessionStorage.setItem('selectedNoteTaskId', String(value));
            }

            if (value != null && this.taskSource == 1) {
              this.showSpinner = true;
              this.getFileNoteList(value);
            }
            if (value != null && this.taskSource == 2) {
              const _emailId = sessionStorage.getItem('selectedEmailNoteTaskId') ? sessionStorage.getItem('selectedEmailNoteTaskId') : sessionStorage.getItem('selectedNoteTaskId')
              this.showSpinner = true;
              this.getFileNoteList(_emailId);
            }
          });
        }
      }
    );
    this.subscribeObjectList.push(subscribeNoteListObj);

    const subscribeEmailNoteListObj = EmailMessageId.subscribe(
      (value: any) => {
        if (value) {
          this.ngZone.run(() => {
            const _emailId = sessionStorage.getItem('selectedEmailNoteTaskId');
            if (_emailId !== value) {
              sessionStorage.setItem('selectedEmailNoteTaskId', String(value));
            }
            if (value != null && this.taskSource == 2) {
              this.showSpinner = true;

              this.getFileNoteList(value);
            }
          });
        }
      }
    );
    this.subscribeObjectList.push(subscribeEmailNoteListObj);
  }
  public subscribeNoteList(): void {
    if (this.taskSource == 1) {
      this.fileNoteId = sessionStorage.getItem('selectedNoteTaskId');
    }
    else if (this.taskSource == 2) {
      this.fileNoteId = sessionStorage.getItem('selectedEmailNoteTaskId') ? sessionStorage.getItem('selectedEmailNoteTaskId') : sessionStorage.getItem('selectedNoteTaskId')
    }

    const subscribeNoteListObj = refreshNoteList.subscribe(
      (value: boolean) => {
        if (value) {
          this.ngZone.run(() => {
            this.showSpinner = true;
            this.getFileNoteList(this.fileNoteId);
          });
        }
      }
    );
    this.subscribeObjectList.push(subscribeNoteListObj);
  }
  openAddNotesDialog(): void {
    const dialogRef = this.dialog.open(FileAddNotesComponent, {
      panelClass: 'ig2',
      width: '500px',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe();
  }

  public openEditNotesDialog(noteInfo: NoteFilter): void {
    const noteId = noteInfo.igpsNoteId;
    const process = GetSelectedProcess();
    const processid = process?.id ? process?.id : sessionStorage.getItem('ActiveProcessId')
    const taskSource = process?.taskSource ? process?.taskSource : sessionStorage.getItem('taskSource')

    if (processid && noteInfo.creatorUser == noteInfo.editorUser) {
      if (taskSource === 1) {
        const subscribeNote = this.fileNoteService.getNoteByNoteId(noteId).subscribe((noteData: NoteFilter) => {
          const dialogRef = this.dialog.open(FileAddNotesComponent, {
            panelClass: 'ig2',
            width: '500px',
            disableClose: true,
            data: {
              noteobj: noteData,
            },
          });
          dialogRef.afterClosed().subscribe();
        });
        this.subscribeObjectList.push(subscribeNote);
      }
      else {
        const subscribeEmailNote = this.fileNoteService.getEmailNoteByNoteId(noteId).subscribe((noteData: EmailNoteFilter) => {
          const dialogRef = this.dialog.open(FileAddNotesComponent, {
            panelClass: 'ig2',
            width: '500px',
            disableClose: true,
            data: {
              noteobj: noteData,
            },
          });
          dialogRef.afterClosed().subscribe();
        });

        this.subscribeObjectList.push(subscribeEmailNote);
      }
    }
  }
  public getFileNoteList(taskId: any) {
    if (taskId) {
      const noteFilterobj = this.fileNoteService
        .getNotesList(taskId)
        .subscribe((noteList: NoteFilter[]) => {
          if (noteList.some((item) => item.isIgniteApiFailed === true)) {
            showToastEvent.emit({ message: this.commonMessages.IgniteNotesError, isError: true });
          }
          noteList.forEach((x: any) => {
            if (x.updatedDate !== null) {
              x.tempDate = x.updatedDate;
            }
            else {
              x.tempDate = x.createdDate;
            }
            if (x.actionType === ActionTypes.COMPLETE_WITH_ERRORS || x.actionType === ActionTypes.COMPLETE || x.actionType === ActionTypes.RETURN_TO_BRANCH) {
              x.isNotValidAction = true;
            }
          });
          // tslint:disable-next-line: only-arrow-functions
          noteList.sort(function (x, y) {
            return (new Date(y.tempDate) as any) - (new Date(x.tempDate) as any);
          });
          this.noteList = noteList;
          this.noteFilerList = noteList;
          this.checkImage();
          this.filterNotes();
          this.showSpinner = false;
        });
      this.subscribeObjectList.push(noteFilterobj);
    }
  }
  checkImage() {
    this.noteList.forEach(val => {
      const image = new Image();
      image.src = val.profileImage;
      // tslint:disable-next-line: no-empty only-arrow-functions
      image.onload = function () { }
      // tslint:disable-next-line: only-arrow-functions
      image.onerror = function () {
        val.profileImage = '';
      }
    })
  }
  public archiveNoteCard(noteId: string, description: string, isArchive: boolean): void {
    this.showSpinner = true;
    const updateNote: UpdateNote = {
      status: isArchive ? this.noteStatusEnum.Archived : this.noteStatusEnum.Active
    };
    if (this.taskSource == 1) {
      const subscribeObject = this.fileNoteService.updateNotesStatus(noteId, updateNote).subscribe(() => {
        const externalSystemTaskId = sessionStorage.getItem('selectedNoteTaskId');
        selectedTaskId.emit(externalSystemTaskId);
      });
      this.subscribeObjectList.push(subscribeObject);
    }
    else {
      const subscribeEmailNote = this.fileNoteService.updateEmailNotesStatus(noteId, updateNote).subscribe(() => {
        const emailMessageId = sessionStorage.getItem('selectedEmailNoteTaskId');
        EmailMessageId.emit(emailMessageId);
      });

      this.subscribeObjectList.push(subscribeEmailNote);
    }
  }

  filterNotes() {
    switch (this.noteStatus) {
      case NoteStatus[NoteStatus.All]:
        this.noteFilerList = this.noteList;
        break;
      case NoteStatus[NoteStatus.Active]:
        this.noteFilerList = this.noteList.filter((obj) => {
          return obj.igniteStatusCd === this.noteStatusEnum.Active;
        });
        break;
      case NoteStatus[NoteStatus.Archived]:
        this.noteFilerList = this.noteList.filter((obj) => {
          return obj.igniteStatusCd === this.noteStatusEnum.Archived;
        });
        break;
      default:
        break;
    }
  }
  public ngOnDestroy(): void {
    if (this.subscribeObjectList.length > 0) {
      this.subscribeObjectList.forEach((s) => {
        if (s) {
          s.unsubscribe();
        }
      });
    }
  }
}
