import { EnvironmentService } from "src/environments/environment.service";
import { AppEndPoint } from "../models/app.endpoint";

export enum FileChecklistEndPointName {
  AllNotes = 'AllNotes',
  Notes = 'Notes',
  EmailNotes = 'EmailNotes',
  SaveTaskNotes = 'SaveTaskNotes',
  SaveEmailNotes = 'SaveEmailNotes',
  UserDP = 'GraphUrl',
  AllActivityLog = 'AllActivityLog',
  AllActivityLogSearch = 'AllActivityLogSearch',
  EmailActivityLogSearch = 'EmailActivityLogSearch',
  AllActivityTypes = 'AllActivityTypes',
  TransacrionPreview = 'TransacrionPreview',
  TransactionParties = 'TransactionParties',
  FastTransacrionPreview = 'FastTransacrionPreview',
  FastTransactionParties = 'FastTransactionParties',
  LogError = 'LogError'
}
export const FileChecklistEndPoint: AppEndPoint[] = [
  {
    EndPointName: FileChecklistEndPointName.Notes,
    EndpointUrl: `${EnvironmentService.environment.apiUrl}/v1/notes`,
  },
  {
    EndPointName: FileChecklistEndPointName.EmailNotes,
    EndpointUrl: `${EnvironmentService.environment.apiEmailUrl}/v1/email`,
  },
  {
    EndPointName: FileChecklistEndPointName.SaveTaskNotes,
    EndpointUrl: `${EnvironmentService.environment.apiUrl}/v1/task/`,
  },
  {
    EndPointName: FileChecklistEndPointName.SaveEmailNotes,
    EndpointUrl: `${EnvironmentService.environment.apiEmailUrl}/v1/email/`,
  },
  {
    EndPointName: FileChecklistEndPointName.AllNotes,
    EndpointUrl: `${EnvironmentService.environment.apiUrl}/v1/notes/all`,
  },
  {
    EndPointName: FileChecklistEndPointName.UserDP,
    EndpointUrl: `${EnvironmentService.environment.graphUrl}/user/photo`,
  },
  {
    EndPointName: FileChecklistEndPointName.AllActivityLog,
    EndpointUrl: `${EnvironmentService.environment.apiUrl}/v1/activitylog/all`,
  },
  {
    EndPointName: FileChecklistEndPointName.AllActivityLogSearch,
    EndpointUrl: `${EnvironmentService.environment.logUrl}activitylog/tasksearch/v1`,
  },
  {
    EndPointName: FileChecklistEndPointName.EmailActivityLogSearch,
    EndpointUrl: `${EnvironmentService.environment.logUrl}activitylog/emailsearch/v1`,
  },
  {
    EndPointName: FileChecklistEndPointName.AllActivityTypes,
    EndpointUrl: `${EnvironmentService.environment.apiUrl}/v1/activitytypes`,
  },
  {
    EndPointName: FileChecklistEndPointName.TransacrionPreview,
    EndpointUrl: `${EnvironmentService.environment.apiUrl}/v1/transaction/preview`,
  },
  {
    EndPointName: FileChecklistEndPointName.TransactionParties,
    EndpointUrl: `${EnvironmentService.environment.apiUrl}/v1/transaction/parties`,
  },
  {
    EndPointName: FileChecklistEndPointName.FastTransacrionPreview,
    EndpointUrl: `${EnvironmentService.environment.apiIgniteServiceUrl}/FastFile/transactionpreview`,
  },
  {
    EndPointName: FileChecklistEndPointName.FastTransactionParties,
    EndpointUrl: `${EnvironmentService.environment.apiIgniteServiceUrl}/FastFile/transactionparties`,
  },
  {
    EndPointName: FileChecklistEndPointName.LogError,
    EndpointUrl: `${EnvironmentService.environment.logUrl}`,
  }
];
