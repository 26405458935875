import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { SubscriptionLike as ISubscription, Subscription, forkJoin, map, mergeMap, of, share, switchMap, tap, timer } from 'rxjs';
import { ActivityLog } from 'src/app/global/models/activity-log';
import { AcivityType } from 'src/app/global/models/activity-type';
import { selectedTaskId, EmailTaskId, onChangeProcess, EmailMessageId, GetSelectedProcess } from '@igps/top-utility';
import { AppInfoService } from 'src/app/global/services/app-info.service';
import { FileActivityLogService } from 'src/app/services/file-activity-log.service';
import { getTimezone, timezone$ } from '@igps/client-root-config';
import { ConvertTimePipe } from '@igps/pipe';
import { Process } from 'src/app/global/models/process';
import { FileInfoService } from 'src/app/services/file-info.service';
@Component({
  selector: 'app-file-activity-log',
  templateUrl: './file-activity-log.component.html',
  styleUrls: ['./file-activity-log.component.scss']
})
export class FileActivityLogComponent implements OnInit, OnDestroy {
  public subscribeObjectList: ISubscription[] = [];
  public activityLogList: ActivityLog[] = [];
  public emailActivityLogList: ActivityLog[] = [];
  public activityLogFilterList: ActivityLog[] = [];
  public activityLog: ActivityLog[] = [];
  public activityTypeList: AcivityType[] = [];
  activityTypeId!: number;
  showSpinner: boolean = false;
  searchText: string = '';
  searchClicked: boolean = false;
  emailTaskID!: string;
  emailMessageId !: string;
  taskSource!: number;
  timezone: { name: string; value: string; abbreviation: string } = { name: "", value: "", abbreviation: "" };
  onSelectedProcessChangeSubscription!: Subscription;
  EmailtaskIdSubscription!: Subscription;

  constructor(private fileInfoService: FileInfoService, public fileActivityLogService: FileActivityLogService, private convertTimePipe: ConvertTimePipe,
    public appInfoService: AppInfoService, private ngZone: NgZone) {

  }

  ngOnDestroy(): void {
    if (this.subscribeObjectList.length > 0) {
      this.subscribeObjectList.forEach((s) => {
        if (s) {
          s.unsubscribe();
        }
      });
    }
  }

  ngOnInit(): void {
    this.subscribeEventEmitter();
    this.subscribeTask();
    this.getActivityTypes();
    this.timezone = getTimezone();
    this.subscribeObjectList.push(timezone$.subscribe((timezone: { name: string; value: string; abbreviation: string }) => {
      this.ngZone.run(() => {
        this.timezone = timezone;
      });
    }));
  }
  public subscribeTask() {
    const subscribeNoteListObj = selectedTaskId.subscribe(
      (value: any) => {
        if (value && this.taskSource == 1) {
          this.ngZone.run(() => {
            sessionStorage.setItem('selectedTaskId', String(value));
            this.getFileActivityList(value, '');
          });
        }
      }
    );
    this.subscribeObjectList.push(subscribeNoteListObj);
    const subscribeEmailNoteListObj = EmailMessageId.subscribe(
      (value: any) => {
        if (value && this.taskSource == 2) {
          this.ngZone.run(() => {
            sessionStorage.setItem('selectedEmailNoteTaskId', String(value));
            this.getFileActivityList(value, '');
          });
        }
      }
    );
    this.subscribeObjectList.push(subscribeEmailNoteListObj);
  }

  public subscribeEventEmitter() {
    this.EmailtaskIdSubscription = EmailTaskId.subscribe((value: string) => {
      this.emailTaskID = value;

    });


    this.onSelectedProcessChangeSubscription = onChangeProcess.subscribe((process: Process) => {
      this.taskSource = process.taskSource;
      const taskID = this.emailTaskID = sessionStorage.getItem('selectedTaskId')!;
      const EmailMessgeID = sessionStorage.getItem('selectedEmailNoteTaskId');
      if (this.taskSource === 1) {
        this.getFileActivityList(taskID, '');
      }
      else if (this.taskSource === 2 && EmailMessgeID) {
        this.getFileActivityList(EmailMessgeID, '');
      }
      else if (this.taskSource === 2 && !EmailMessgeID) {
        this.getFileActivityList(taskID, '');
      }
    });
    if (this.taskSource === undefined) {
      const process = GetSelectedProcess();
      if (process?.id) {
        this.taskSource = process.taskSource
      }
      else {
        let x = sessionStorage.getItem('taskSource')
        this.taskSource = parseInt(x ? x : '')
      }
    }

  }
  public getFileActivityList(taskId: any, searchString: string) {
    // activity log when task source is task
    this.activityLogFilterList = [];

    if (taskId && this.taskSource != undefined) {
      this.showSpinner = true;
      const EmailMessgeID = sessionStorage.getItem('selectedEmailNoteTaskId');
      if (this.taskSource === 1) {
        const activityLogobj = this.fileActivityLogService
          .getActivityLogSearch(taskId.toString(), searchString)
          .subscribe((activityLogList: ActivityLog[]) => {
            this.activityLogList = activityLogList.map(al => {
              return { ...al, description: this.convertTimePipe.convertTimesIfPresentInString(al.description, "utc", this.timezone.value) };
            });
            this.checkImage();
            this.filterActivity();
            this.showSpinner = false;
            // tslint:disable-next-line: only-arrow-functions
            this.activityLogList.sort(function (x, y) {
              if (x.updatedDate !== null && y.updatedDate !== null) {
                return new Date(y.updatedDate).valueOf() - new Date(x.updatedDate).valueOf();
              }
              return new Date(x.updatedDate).valueOf();
            });
            this.activityLogFilterList = this.activityLogList;
          });
        this.subscribeObjectList.push(activityLogobj);
      }
      // activity log when tasksource is email but no task id is available
      else if ((this.emailTaskID === null || this.emailTaskID === undefined) && this.taskSource === 2) {
        const emailActivityLogobj = timer(1000).pipe(
          mergeMap(() => {
            return this.fileActivityLogService.getEmail_ActivityLogSearch(String(EmailMessgeID), searchString);
          })
        ).subscribe((emailActivityLogList: ActivityLog[]) => {
          this.emailActivityLogList = emailActivityLogList.map(al => {
            return { ...al, description: this.convertTimePipe.convertTimesIfPresentInString(al.description, "utc", this.timezone.value) };
          });
          this.checkImage();

          this.showSpinner = false;
          // tslint:disable-next-line: only-arrow-functions
          this.emailActivityLogList.sort(function (x, y) {
            if (x.createdDate !== null && y.createdDate !== null) {
              return new Date(y.createdDate).valueOf() - new Date(x.createdDate).valueOf();
            }
            return new Date(x.createdDate).valueOf();
          });
          this.emailActivityLogList.forEach(val => {
            if (val.taskActivityTypeId === 536 || val.taskActivityTypeId === 537 || val.taskActivityTypeId === 538 || val.taskActivityTypeId === 550) {
              val.profileImage = 'assets/svg/icon-ignite-avatar.svg';
            }
            else if (val.taskActivityTypeId === 12 || val.taskActivityTypeId === 2) {
              val.profileImage = 'assets/svg/ips-icon.svg';
              val.createdUser = 'IgnitePS';
            }
            else {
              const image = new Image();
              image.src = val.profileImage;
              // tslint:disable-next-line: no-empty only-arrow-functions
              image.onload = function () { }
              // tslint:disable-next-line: only-arrow-functions
              image.onerror = function () {
                val.profileImage = '';
              }
            }
          })
          this.activityLogFilterList = this.emailActivityLogList;
        });
        this.subscribeObjectList.push(emailActivityLogobj);
      }
      // activity log when tasksource is email and email id is not available
      else if (this.taskSource == 2 && !EmailMessgeID) {
        const activityLogobj = this.fileActivityLogService
          .getActivityLogSearch(taskId.toString(), searchString)
          .subscribe((activityLogList: ActivityLog[]) => {
            this.activityLogList = activityLogList.map(al => {
              return { ...al, description: this.convertTimePipe.convertTimesIfPresentInString(al.description, "utc", this.timezone.value) };
            });
            this.checkImage();
            this.filterActivity();
            this.showSpinner = false;
            // tslint:disable-next-line: only-arrow-functions
            this.activityLogList.sort(function (x, y) {
              if (x.updatedDate !== null && y.updatedDate !== null) {
                return new Date(y.updatedDate).valueOf() - new Date(x.updatedDate).valueOf();
              }
              return new Date(x.updatedDate).valueOf();
            });
            this.activityLogFilterList = this.activityLogList;
          });
        this.subscribeObjectList.push(activityLogobj);
      }
      // activity log when tasksource is email and task id is available
      else {
        const activityLogobj = timer(1000).pipe(
          mergeMap(() => {
            return this.fileActivityLogService.getActivityLogSearch(this.emailTaskID?.toString(), searchString);
          })
        ).pipe(switchMap((res) => {
          this.activityLogList = res.map(al => {
            return { ...al, description: this.convertTimePipe.convertTimesIfPresentInString(al.description, "utc", this.timezone.value) };
          });
          this.checkImage();
          this.showSpinner = false;

          return forkJoin([this.fileActivityLogService.getEmail_ActivityLogSearch(String(EmailMessgeID), searchString), of(this.activityLogList)])
        })
        ).subscribe(([res1, res2]) => {
          const result = res1.concat(res2);
          // tslint:disable-next-line:no-var-requires
          result.sort((function (x, y) {
            if (x.createdDate !== null && y.createdDate !== null) {
              return new Date(y.createdDate).valueOf() - new Date(x.createdDate).valueOf();
            }
            return new Date(x.createdDate).valueOf();
          }))
          result.forEach(val => {
            if (val.taskActivityTypeId === 536 || val.taskActivityTypeId === 537 || val.taskActivityTypeId === 538 || val.taskActivityTypeId === 550) {
              val.profileImage = 'assets/svg/icon-ignite-avatar.svg';
            }
            else if (val.taskActivityTypeId === 12 || val.taskActivityTypeId === 2) {
              val.profileImage = 'assets/svg/ips-icon.svg';
              val.createdUser = 'IgnitePS';
            }
            else {
              const image = new Image();
              image.src = val.profileImage;
              // tslint:disable-next-line: no-empty only-arrow-functions
              image.onload = function () { }
              // tslint:disable-next-line: only-arrow-functions
              image.onerror = function () {
                val.profileImage = '';
              }
            }
          })
          this.activityLogFilterList = result;
          return result;
        });

        this.subscribeObjectList.push(activityLogobj);
      }
    }
  }
  checkImage() {
    this.activityLogList.forEach(val => {
      if (val.taskActivityTypeId === 536 || val.taskActivityTypeId === 537 || val.taskActivityTypeId === 538 || val.taskActivityTypeId === 550) {
        val.profileImage = 'assets/svg/icon-ignite-avatar.svg';
      }
      else if (val.taskActivityTypeId === 12 || val.taskActivityTypeId === 2) {
        val.profileImage = 'assets/svg/ips-icon.svg';
        val.createdUser = 'IgnitePS';
      }
      else {
        const image = new Image();
        image.src = val.profileImage;
        // tslint:disable-next-line: no-empty only-arrow-functions
        image.onload = function () { }
        // tslint:disable-next-line: only-arrow-functions
        image.onerror = function () {
          val.profileImage = '';
        }
      }
    })
  }
  public getActivityTypes() {
    const activityTypesobj = this.fileActivityLogService
      .getActivityTypes().subscribe(resp => this.activityTypeList = resp);
    this.subscribeObjectList.push(activityTypesobj);
  }
  searchActivityLog() {
    this.searchClicked = true;
    if (this.taskSource == 1) {
      const externalSystemTaskId = sessionStorage.getItem('selectedTaskId');
      this.getFileActivityList(externalSystemTaskId, this.searchText);
    }
    else if (this.taskSource == 2) {
      const emailMessageId = sessionStorage.getItem('selectedEmailNoteTaskId');
      this.getFileActivityList(emailMessageId, this.searchText);
    }
  }
  cleraSearchActivityLog() {
    this.searchText = '';
    if (this.searchClicked) {
      this.searchActivityLog();
      this.searchClicked = false;
    }
  }
  filterActivity() {
    this.activityLogFilterList = this.activityLogList;
    if (this.activityTypeId && this.activityTypeId !== 0) {
      this.activityLogFilterList = this.activityLogList.filter((obj) => {
        return obj.taskActivityTypeId === this.activityTypeId;
      });
    }
  }
  searchKeydown(event: KeyboardEvent) {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      if (this.searchText.length === 0) {
        this.cleraSearchActivityLog();
      }
    }
  }
}
