export class DisplayTrnsactionDetail {
    businessSegmentName?: string;
    transactionType?: string;
    openDate?: string;
    estimatedSettlementDate?: string;
    settlementDate?: string;
    prorateDate?: string;
    salesPrice?: string;
    firstNewLoan?: string;
    secondNewLoan?: string;
    thirdNewLoan?: string;
    fourthNewLoan?: string;
    fifthNewLoan?: string;
    sixthNewLoan?: string;
    seventhNewLoan?: string;
    eighthNewLoan?: string;
    ninethNewLoan?: string;
    businessPrograms?: string;
    shortLegal?: string;
  }