<article class="util-tabs__alert"></article>
<!-- end: util tabs alert -->
<mat-tab-group class="mat-tab-group-nested file-utility-tab" animationDuration="0ms">
  <mat-tab class="" label="Notes">
    <app-file-notes></app-file-notes>
  </mat-tab>
  <mat-tab *ngIf="isFileActivityEnabled" label="Activity Log">
    <app-file-activity-log></app-file-activity-log>
  </mat-tab>
</mat-tab-group>
