import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { InfoCard } from '@ui-core/info-card';
import { SubscriptionLike as ISubscription, finalize } from 'rxjs';
import { TransactionInfoParty } from 'src/app/global/models/transaction-info-party';
import { TransactionParties } from 'src/app/global/models/transaction-parties';
import { FileInfoService } from 'src/app/services/file-info.service';
import { TransactionInfoService } from 'src/app/services/transaction-info-service';
import { TransactionPartiesService } from 'src/app/services/transaction-parties-service';
import { selectedTaskId, SearchResultTaskId } from '@igps/top-utility';

@Component({
  selector: 'app-file-info',
  templateUrl: './file-info.component.html',
  styleUrls: ['./file-info.component.scss']
})
export class FileInfoComponent implements OnInit, OnDestroy {
  public subscribeObjectList: ISubscription[] = [];
  selectedIndex: number = 0;
  header = '';
  fileNumber!: string;
  address = '';
  isFromDashboard: boolean = false;
  transactionDetails: InfoCard[] = [];
  transactionTeams: InfoCard[] = [];
  transactionPartiesDetail!: TransactionParties;
  partyDetails: any = [];
  partiesHolder: TransactionInfoParty[] = [];
  showSpinner: boolean = false;
  isEmailSearchResult: boolean = false;
  constructor(private transactionInfoService: TransactionInfoService,
    private transactionPartiesService: TransactionPartiesService,
    private ngZone: NgZone) { }

  ngOnInit(): void {
    this.getTransactionTabInfoService();
    this.subscribeTask();
    this.subscribeSearchResultTask();
  }
  public getTransactionTabInfoService(): void {
    const transactionDetailSub = this.transactionInfoService.updatedTransactionDetails$.subscribe((data) => {
      this.transactionDetails = data;
      this.showSpinner = false;
    });

    const transactionTeamsSub = this.transactionInfoService.updatedTransactionTeam$.subscribe((data) => {
      this.transactionTeams = data;
    });

    const transactionHeaderSub = this.transactionInfoService.updatedTransactionHeader$.subscribe((data) => {
      this.header = data.header;
      this.fileNumber = data.fileNumber;
      this.address = data.address;
    });
    const transactionPartiesSub = this.transactionPartiesService.updatedTransactionParties$.subscribe((data) => {
      this.partyDetails = data;
    });
    this.subscribeObjectList.push(transactionDetailSub, transactionTeamsSub, transactionHeaderSub, transactionPartiesSub);
  }
  public subscribeTask() {
    const subscribeNoteListObj = selectedTaskId.
      pipe(
        finalize(() => {
          this.showSpinner = false;
        })
      ).subscribe({
        next:
          (value: any) => {
            if (value) {
              this.ngZone.run(() => {
                this.isEmailSearchResult = false;
                sessionStorage.setItem('selectedTaskId', String(value));
                this.showSpinner = true;
                this.getTransactionPreview(value, false);
                this.getPartyDetails(value, false);
                this.showSpinner = false;
              });
            }
          },
        error: () => {
          this.showSpinner = false;
        }
      });
    this.subscribeObjectList.push(subscribeNoteListObj);
  }
  public subscribeSearchResultTask() {
    const subscribeResultListObj = SearchResultTaskId.
      pipe(
        finalize(() => {
          this.showSpinner = false;
        })
      ).subscribe({
        next:
          (value: any) => {
            if (value) {
              this.ngZone.run(() => {
                sessionStorage.setItem('SearchResultTaskId', String(value));
                this.showSpinner = true;
                this.getTransactionPreview(value, true);
                this.getPartyDetails(value, true);
                this.isEmailSearchResult = true;
                this.showSpinner = false;
              });
            }
          },
        error: () => {
          this.showSpinner = false;
        }
      });
    this.subscribeObjectList.push(subscribeResultListObj);
  }
  getTransactionPreview(externalSystemTaskId: number, isFromEmail: boolean) {
    this.transactionInfoService.getTransactionsPreview(externalSystemTaskId, isFromEmail);
  }
  // parties content
  getPartyDetails(externalSystemTaskId: number, isFromEmail: boolean): void {
    this.transactionPartiesService.getPartyDetails(externalSystemTaskId, isFromEmail);
  }
  ngOnDestroy(): void {
    if (this.subscribeObjectList.length > 0) {
      this.subscribeObjectList.forEach((s) => {
        if (s) {
          s.unsubscribe();
        }
      });
    }
  }
}
