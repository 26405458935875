<!-- start: utility tabs -->
<section class="util-tabs">
    <!-- start: tabs -->
    <mat-tab-group #utilityTabGroup animationDuration="0ms" (selectedTabChange)="utilityTabChange($event)" [selectedIndex]="selectedIndex">
      <mat-tab label="Activity">
        <ng-template mat-tab-label>
          <svg
            class="util-tabs__icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
              <path
                d="M13.451 21.5c-.028 0 .029 0 0 0-.295 0-.499-.228-.565-.475L8.296 6.012l-2.66 8.678a.57.57 0 01-.548.4H.571A.567.567 0 010 14.527c0-.31.256-.562.571-.562h4.093L7.75 3.9a.57.57 0 01.547-.4.57.57 0 01.547.4l4.61 15.08L16.62 8.655a.57.57 0 01.547-.4.57.57 0 01.547.4l1.624 5.309h4.092c.315 0 .571.252.571.562 0 .31-.256.563-.571.563h-4.517a.57.57 0 01-.547-.4l-1.2-3.922L13.999 21.1a.57.57 0 01-.547.4z"
                id="icon-activity-a" />
            </defs>
            <g transform="translate(0 -.5)" fill="none" fill-rule="evenodd">
              <mask id="icon-activity-b" fill="#fff">
                <use xlink:href="#icon-activity-a" />
              </mask>
              <use fill="#000" fill-rule="nonzero" xlink:href="#icon-activity-a" />
              <g mask="url(#icon-activity-b)">
                <path fill="#043B7C" d="M0 .5h24v24H0z" />
              </g>
            </g>
          </svg>
          <h5>Activity</h5>
        </ng-template>
        <app-file-activity></app-file-activity>
      </mat-tab>
  
      <mat-tab label="File Info">
        <ng-template mat-tab-label>
          <svg
            class="util-tabs__icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
              <path
                d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11zm0-1C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm.005-13.436c.677 0 1.23-.517 1.23-1.151 0-.629-.553-1.145-1.23-1.145-.67 0-1.225.516-1.225 1.145 0 .634.554 1.15 1.225 1.15zm1.13 9.236V9.618h-2.27V17.8h2.27z"
                id="icon-info-a" />
            </defs>
            <g fill="none" fill-rule="evenodd">
              <mask id="icon-info-b" fill="#fff">
                <use xlink:href="#icon-info-a" />
              </mask>
              <g mask="url(#icon-info-b)" fill="#043B7C">
                <path d="M0 0h24v24H0z" />
              </g>
            </g>
          </svg>
          <h5>File Info</h5>
        </ng-template>
        <app-file-info></app-file-info>
      </mat-tab>
  
      <mat-tab label="Notifications">
        <ng-template mat-tab-label>
          <div (click)="loadNotifications()">
            <!-- refactor img -->
            <img class="util-tabs__icon" width="24" height="24" src="assets/icons/icon-notifications-dark.svg" alt="notification icon" />
            <div class="notification_text">
              <h5>Notifications</h5>
              <div *ngIf="fileChecklistNotification" class="dot"></div>
            </div>
          </div>
        </ng-template>
        <!-- <app-file-notifications></app-file-notifications> -->
      </mat-tab>  
   
    </mat-tab-group>
    <!-- end: tabs component -->
  </section>
  <!-- end: utility tabs -->
