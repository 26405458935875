import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { FileChecklistEndPoint, FileChecklistEndPointName } from '../global/components/file-checklist.endpoint';
import { CreateEmailNote, CreateNote } from '../global/models/create-note';
import { EmailNoteFilter, NoteFilter } from '../global/models/note-filter';
import { UpdateNote } from '../global/models/update-note';
import { UserPhoto } from '../global/models/user-photo';


@Injectable({
  providedIn: 'root'
})
export class FileNotesService {
  constructor(private httpClient: HttpClient) { }

  getNotesList(externalSystemTaskId: number): Observable<NoteFilter[]> {
    const url = FileChecklistEndPoint.find((n) => n?.EndPointName === FileChecklistEndPointName.AllNotes)?.EndpointUrl;
    return this.httpClient.get<NoteFilter[]>(`${url}/${externalSystemTaskId}`);
    // .pipe(
    //   map((notes: NoteFilter[]) => {
    //     return notes;
    //   })
    // );
  }
  public getNoteByNoteId(noteId: string): Observable<NoteFilter> {
    const url = FileChecklistEndPoint.find((n) => n?.EndPointName === FileChecklistEndPointName.Notes)?.EndpointUrl;
    return this.httpClient.get<NoteFilter>(`${url}/${noteId}`).pipe(
      map((note: NoteFilter) => {
        return note;
      })
    );
  }

  public getEmailNoteByNoteId(noteId: string): Observable<EmailNoteFilter> {
    const url = FileChecklistEndPoint.find((n) => n?.EndPointName === FileChecklistEndPointName.EmailNotes)?.EndpointUrl;
    return this.httpClient.get<EmailNoteFilter>(`${url}/${noteId}`).pipe(
      map((note: EmailNoteFilter) => {
        return note;
      })
    );
  }
  public saveTaskNotes(taskId: any, note: CreateNote): Observable<CreateNote> {
    const url = `${FileChecklistEndPoint.find((n) => n?.EndPointName === FileChecklistEndPointName.SaveTaskNotes)?.EndpointUrl
      }${taskId}/notes`;
    return this.httpClient.post<CreateNote>(url, note).pipe(
      map((noteData: CreateNote) => {
        return noteData;
      })
    );
  }

  public saveEmailNotes(emailId: any, note: CreateEmailNote): Observable<CreateEmailNote> {
    const url = `${FileChecklistEndPoint.find((n) => n?.EndPointName === FileChecklistEndPointName.SaveEmailNotes)?.EndpointUrl
      }${emailId}/notes`;
    return this.httpClient.post<CreateEmailNote>(url, note).pipe(
      map((noteData: CreateEmailNote) => {
        return noteData;
      })
    );
  }

  public updateNotes(noteId: string, note: UpdateNote) {
    const url = `${FileChecklistEndPoint.find((n) => n?.EndPointName === FileChecklistEndPointName.Notes)?.EndpointUrl
      }/${noteId}`;
    return this.httpClient.put(url, note).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  public updateEmailNotes(noteId: string, note: UpdateNote) {
    const url = `${FileChecklistEndPoint.find((n) => n?.EndPointName === FileChecklistEndPointName.EmailNotes)?.EndpointUrl
      }/${noteId}`;
    return this.httpClient.put(url, note).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  public updateNotesStatus(noteId: string, note: UpdateNote) {
    const url = `${FileChecklistEndPoint.find((n) => n?.EndPointName === FileChecklistEndPointName.Notes)?.EndpointUrl
      }/${noteId}/status`;
    return this.httpClient.put(url, note).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  public updateEmailNotesStatus(noteId: string, note: UpdateNote) {
    const url = `${FileChecklistEndPoint.find((n) => n?.EndPointName === FileChecklistEndPointName.EmailNotes)?.EndpointUrl
      }/${noteId}/status`;
    return this.httpClient.put(url, note).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  getUserImage(email: string): Observable<UserPhoto> {
    const url = `${FileChecklistEndPoint.find((n) => n?.EndPointName === FileChecklistEndPointName.UserDP)?.EndpointUrl}/${email}`;
    return this.httpClient.get<UserPhoto>(url);
  }
}
